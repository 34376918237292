import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';

const ContactFormModal = dynamic(() => import('@/components/StaffComponents/ContactFormModal'));

export default function StaffCard({ featuredImage, title, location, role, content, uri, branchPage, desId }) {
    // Although location is an array, we are only grabbing the first location for simplicity
    const { uri: branchUri, title: branchTitle } = location?.[0] || [];
    return (
        <>
            <div className="staff-image-container mb-6 text-center lg:mb-0 lg:w-1/4 lg:pr-5">
                <Image className="mx-auto rounded-full" src={featuredImage?.node?.sourceUrl} alt={title} width="200" height="200" />
            </div>
            <div className="staff-info lg:w-3/4">
                {branchUri ? (
                    <h3 className="text-center lg:pl-4 lg:text-left">
                        {title} <span className="hidden lg:inline">|</span>{' '}
                        <Link className="my-1 ml-2 block font-bold text-lightBlue md:my-0 md:inline lg:ml-0" href={branchUri}>
                            {branchTitle}
                        </Link>
                    </h3>
                ) : (
                    <h3 className="text-center lg:pl-4 lg:text-left">{title}</h3>
                )}
                <p className="text-center text-[#777] lg:pl-4 lg:text-left">{role}</p>
                <div className="content-container mx-4 mt-4" dangerouslySetInnerHTML={{ __html: content }} />
                <ContactFormModal title={title} />
                {branchPage && desId && (
                    <>
                        <span className="mx-2">|</span>
                        <Link className="font-bold text-lightBlue" hrefLang="en" title="Donate" href={`${uri}#donate`}>
                            Donate
                        </Link>
                    </>
                )}
            </div>
        </>
    );
}
